import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const query = graphql`
    query {
      aktuelles: directusCustomData(title: {eq: "aktuelles"}) {
        title
        content {
          content
          display_name
        }
      }
    }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderMedia id={'aktuelles'} mdxType="HeaderMedia" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Aktuelles`}</h1>
      <div>
    {props.data.aktuelles.content.map(item => <div key={item.display_name} dangerouslySetInnerHTML={{
          __html: item.content
        }} />)}
      </div>
      <hr></hr>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      